import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { getInvestorTypes } from '../../utils/investor';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, Input, NumericTextBox, TextBox } from '@progress/kendo-react-inputs';
import { formatNumber } from '@telerik/kendo-intl';
import * as Moment from 'moment';
import { EditableAddress } from '../EditableAddress';
import { useNavigate, useLoaderData, Params } from 'react-router';
import { Title } from '../../utils/title';
import { ViewModel } from 'TypeGen/investor/get/view-model';

export const InvestorProfileLoader = (params: Params<string>) => {
    return fetchApi(`/api/Investor/Get/${params.InvestorId}`);
}

const investorTypeOptions = getInvestorTypes();

const InvestorProfile = () => {

    const navigate = useNavigate();
    const response = useLoaderData() as { isAdmin: boolean, investor: ViewModel };

    const [loading, setLoading] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);
    const admin = response.isAdmin;
    const [investor, setInvestor] = React.useState<ViewModel>(response.investor);

    const updateInvestorState = (value: Partial<ViewModel>) => {
        const newInvestor = Object.assign({}, investor, value);
        setInvestor(newInvestor);
        setDirty(true);
    }

    const retireInvestor = () => {
        const newInvestor = Object.assign({}, investor, { Active: false });
        save(newInvestor);
    }

    const unRetireInvestor = () => {
        const newInvestor = Object.assign({}, investor, { Active: true });
        save(newInvestor);
    }

    const save = (newInvestor: ViewModel) => {
        if (newInvestor) {
            const data: any = Object.assign({}, newInvestor);
            if (!data.Address.AddressLine1) delete data.Address;
            fetchApi(`/api/Investor/Edit`, data, 'POST')
                .then(() => {
                    setDirty(false);
                    setLoading(false);
                    navigate("/Investor/Index");
                }).catch((message: Error) => {
                    setLoading(false);
                    alert(message);
                });
        }
    }

    return <>
        {loading && <LoadingPanel />}
        <div className="k-form k-form-md container">
            <Title string={investor.FirstName} />

            <div className='row'>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>First Name</Label>
                        <Input
                            required
                            readOnly={loading}
                            onChange={e => updateInvestorState({ FirstName: e.value })}
                            value={investor.FirstName}
                        />
                    </FieldWrapper>
                </div>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Last Name</Label>
                        <Input
                            required
                            readOnly={loading}
                            onChange={e => updateInvestorState({ LastName: e.value })}
                            value={investor.LastName}
                        />
                    </FieldWrapper>
                </div>
            </div>

            <FieldWrapper>
                <Label>Vesting Name</Label>
                <Input
                    maxLength={50}
                    placeholder="If blank will use First Name and Last Name"
                    readOnly={loading}
                    onChange={e => updateInvestorState({ VestingName: e.value })}
                    value={investor.VestingName}
                />
            </FieldWrapper>

            <FieldWrapper>
                <Label>Mailing Address</Label>
                <EditableAddress
                    title="Mailing Address"
                    readOnly={loading}
                    data={investor.Address}
                    save={async (e) => {
                        updateInvestorState({ Address: e });
                    }}
                />
            </FieldWrapper>

            <FieldWrapper>
                <Label>Investor Type</Label>
                <DropDownList
                    data={investorTypeOptions}
                    dataItemKey="ID"
                    textField="Name"
                    value={investor ? investorTypeOptions.filter(x => x.ID === investor?.InvestorType)[0] : null}
                    onChange={(e) => {
                        updateInvestorState({ InvestorType: e.target.value.ID })
                    }}
                />
            </FieldWrapper>

            <div className='row'>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Email Address</Label>
                        <Input
                            required
                            readOnly={loading}
                            type="email"
                            maxLength={100}
                            onChange={e => updateInvestorState({ Email: e.value })}
                            value={investor.Email}
                        />
                    </FieldWrapper>
                </div>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Email Address 2</Label>
                        <Input
                            readOnly={loading}
                            type="email"
                            maxLength={100}
                            onChange={e => updateInvestorState({ Email2: e.value })}
                            value={investor.Email2}
                        />
                    </FieldWrapper>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Phone Number</Label>
                        <TextBox
                            readOnly={loading}
                            className="flex-fill"
                            value={investor.PhoneNumber}
                            onChange={(e) => updateInvestorState({ PhoneNumber: e.value as string })}
                        />
                    </FieldWrapper>
                </div>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Phone Number 2</Label>
                        <TextBox
                            readOnly={loading}
                            className="flex-fill"
                            value={investor.PhoneNumber2}
                            onChange={(e) => updateInvestorState({ PhoneNumber2: e.value as string })}
                        />
                    </FieldWrapper>
                </div>
            </div>

            <FieldWrapper>
                <Label>Referred By</Label>
                <Input
                    maxLength={50}
                    readOnly={loading}
                    onChange={e => updateInvestorState({ HowYouHeard: e.value })}
                    value={investor.HowYouHeard}
                />
            </FieldWrapper>

            <FieldWrapper>
                <Label>App Referral</Label>
                <DropDownList
                    value={investor.AppReferrals.find(x => x.AppReferralID === investor.AppReferralID)}
                    data={investor.AppReferrals}
                    dataItemKey="AppReferralID"
                    textField="Description"
                    onChange={e => updateInvestorState({ AppReferralID: e.target.value.AppReferralID })}
                />
            </FieldWrapper>

            <FieldWrapper>
                <Label>Max Investment Amount</Label>
                <NumericTextBox
                    required
                    readOnly={loading}
                    format="c2"
                    step={0}
                    value={investor.MaxInvestmentAmount}
                    onChange={(e) => updateInvestorState({ MaxInvestmentAmount: e.value || 0 })}
                />
            </FieldWrapper>

            <div className='row'>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Created On</Label>
                        <span className='k-widget'>
                            {Moment.utc(investor.CreatedDateTime).local().format("MM/DD/YYYY HH:mm")}
                        </span>
                    </FieldWrapper>
                </div>
                <div className='col-md-6'>
                    <FieldWrapper>
                        <Label>Points Earned</Label>
                        <span className='k-widget'>
                            {formatNumber(investor.PointsEarned, 'n0')}
                        </span>
                    </FieldWrapper>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="k-form-field mb-1">
                        <Checkbox
                            checked={investor.SignupFeePaid}
                            disabled={!admin}
                            label="Signup Fee Paid"
                            onChange={(e) => updateInvestorState({ SignupFeePaid: e.value })}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="k-form-field mb-1">
                        <Checkbox
                            checked={investor.IsEmployee}
                            disabled={!admin}
                            label="Belwood Employee"
                            onChange={(e) => updateInvestorState({ IsEmployee: e.value })}
                        />
                    </div>
                </div>
            </div>

            <div className="btn-toolbar justify-content-between my-2">
                {investor?.StripeCustomerID && <Button onClick={() => window.open(`https://dashboard.stripe.com/customers/${investor?.StripeCustomerID}`)}>Open Stripe</Button>}
                <div className="btn-group">
                    {admin && !investor.Active &&
                        <Button onClick={() => window.confirm("Un-retire investor?") && unRetireInvestor()}>Un-Retire Investor</Button>}
                    {admin && investor.Active &&
                        <Button onClick={() => window.confirm("Retire investor?") && retireInvestor()}>Retire Investor</Button>}
                    <Button themeColor="primary" className="ms-2" disabled={!dirty} onClick={() => save(investor)}>Update</Button>
                    </div>
            </div>
        </div>
    </>;
}

export default InvestorProfile;
